// import { NotificationManager } from "react-notifications";
// import { cmsInstance } from "../axios/axiosconfig";

// eslint-disable-next-line import/prefer-default-export
// export const getPageContent = async (page: string, language: string) => {
export const getPageContent = async (page: string, language: string) => {
  try {
    // const {
    //   data: { content, msg },
    //   status,
    // } = await cmsInstance.get(`/content${page}/${language}`);
    // if (status === 200) {
    //
    // }
    const content = {
      home: "string",
      aboutus: page,
      subheading: language,
      aboutheading: "string",
      stringcreate: "string",
      stringdescription: "string",
      notification1: "string",
      notification2: "string",
      heading1: "string",
      heading2: " string",
      heading3: " string",
      heading4: " string",
      heading5: " string",
      para1: "string",
      para2: "string",
      para3: "string",
      para4: "string",
      para5: "string",
      para6: "string",
      para7: "string",
      para8: "string",
      para9: "string",
      para10: "string",
      label1: "string",
      label2: "string",
      label3: "string",
      label4: "string",
      label5: "string",
      placeholder1: "string",
      placeholder2: "string",
      placeholder3: "string",
      placeholder4: "string",
      placeholder5: "string",
      name: "string",
      submit: "string",
      betAmount: 2,
      close: "string",
      contentfirst: "string",
      ourteam: "string",
      transaction_security: "string",
      responsible_gaming: "string",
      pay: "string",
      ourvision: "string",
      text1: "string",
      socialmedia: "string",
      link1: "string",
      link2: "string",
      link3: "string",
      link4: "string",
      link5: "string",
      link6: "string",
      link7: "string",
      link8: "string",
      link9: "string",
      link10: "string",
      link11: "string",
      link12: "string",
      link13: "string",
      link14: "string",
      link15: "string",
      link16: "string",
      link17: "string",
      howto: "string",
      legalnotes: "string",
      about: "string",
      copyright: "string",
      redirect: "string",
    };
    return content;
    // NotificationManager.error("error msg", "Message", 10000);
  } catch (error) {
    console.error("Error", (error as Error).message);
  }
};
