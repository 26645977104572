/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import Helmet from "react-helmet";
import { Button } from "react-bootstrap";
import Layout from "../layout";
import Timer from "./Timer";
import Popup from "./popup";
import Bet from "./Bet/Bet";
import "./home.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { userInstance } from "../../axios/axiosconfig";
import PopularGames from "./PopularGames/PopularGames";
import HowToTournament from "./HowToTournament/HowToTournament";
import DayOfDuel from "./DayOfDuel/DayOfDuel";
import GameTable from "./gametable";
import CreatingTournament from "./CreatingTournament/CreatingTournament";
import useLanguage from "../../hooks/useLanguage/useLanguage";
import { getPageContent } from "../../helpers/content";
import Gallery from "./Gallery/Gallery";
import useProfile from "../../hooks/useProfile/useProfile";
import TextCard from "./TextCard/TextCard";
import { crossedSwordsIcon, podiumIcon, globeIcon } from "../../assets/images";

function Home() {
  const [gametableContent, setgametableContnet] = useState({});
  const { i18n } = useTranslation();
  const [pageContent, setPageContent] = useState({});
  const [startTimer, setstartTimer] = useState(false);
  const [matched, setMatched] = useState(false);
  const roomId = null;
  const [playerOne, setPlayerOne] = useState(null);
  const playerTwo = null;
  // const [playerDetails, setPlayerDetails] = useState(null);
  const [banned, setBanned] = useState(null);
  const [bet, setBet] = useState(false);
  const [betAmount, setBetAmount] = useState(null);
  const [wallettype, setWallettype] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gametype, setGameType] = useState("Pong");
  const profile = useProfile();

  const page = "/home";
  const { shortLanguage } = useLanguage();

  // const loadRoom = async () => {
  //   const { data } = await userInstance.post("/createRoom");
  //   const { roomId } = data;
  //   if (roomId) {
  //     window.location.href = `/winterGame?roomId=${roomId}`;
  //   } else {
  //     window.location.href = "/login";
  //   }
  // };
  const handleBetAmount = () => {
    // if(localStorage.getItem('token')){
    //   setBet(true);
    // }else{
    //   window.location.href = '/login';
    // }
    const elmnt = document.getElementById("populargame");
    elmnt.scrollIntoView();
  };

  const CloseBetComponent = () => {
    setBet(false);
  };

  const matchRoom = async (amount, walletType) => {
    // const res = await adminInstance.post("/getCommission");
    // const cutOff = ((res.data.rate * amount) / 100).toFixed(2);
    // const response = await userInstance.post("/getprofile");

    setBetAmount(amount);
    setBet(false);
    setWallettype(walletType);
    setMatched(true);
  };
  const handleTimerPopup = (e) => {
    if (e === "close") {
      setstartTimer(false);
      // socket.emit("cancel", {
      //   Userid
      // });
    }
  };
  const getgameTableContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const {
        data: { content, msg },
        status,
      } = await userInstance.get("/game/popular?locale=pl-PL");
      if (status === 200) {
        setgametableContnet(content);
        setLoading(true);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  useEffect(() => {
    getPageContent(page, shortLanguage).then((data) => setPageContent(data));
    getgameTableContent();
  }, [shortLanguage]);

  useEffect(() => {
    if (!profile?.data) {
      return;
    }
    setBanned(profile.data.status.Banned);
    setPlayerOne(profile.data);
  }, [profile]);

  if (!pageContent) {
    return <div>Not Found</div>;
  }

  return (
    <Layout>
      <Helmet>
        <body className="home-page" />
      </Helmet>

      <div className="home">
        {matched && (
          <Popup
            roomId={roomId}
            setMatched={setMatched}
            playerOne={playerOne}
            playerTwo={playerTwo}
            amount={betAmount}
            wallettype={wallettype}
            gameType={gametype}
          />
        )}

        {bet && (
          <Bet
            matchRoom={matchRoom}
            CloseBetComponent={CloseBetComponent}
          />
        )}

        <div className="home-banner">
          <div className="container">
            <div className="home-text">
              <h2>{pageContent.home && pageContent.home.futureexperience}</h2>
              <h3>{pageContent.home && pageContent.home.para1}</h3>
              <div className="home-btns">
                {banned ? (
                  <h1>{pageContent.home && pageContent.home.banned}</h1>
                ) : (
                  <Button
                    className="blue-btn"
                    onClick={handleBetAmount}
                  >
                    {pageContent.home && pageContent.home.startplaying}
                  </Button>
                )}
                {startTimer && (
                  <Timer
                    startTimer={startTimer}
                    handleTimerPopup={handleTimerPopup}
                  />
                )}
                <Button className="border-btn">
                  <Link to="/howitworks">{pageContent.home && pageContent.home.howitworks}</Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="introduction-section">
          <div className="section-headings">
            <h3>
              DLACZEGO <span>WARTO?</span>
            </h3>
            <p>Rozwijaj swoje umiejętności, rywalizuj na globalnym poziomie i wygrywaj nagrody pieniężne na innowacyjnej platformie e-sportowej.</p>
          </div>
          <div className="text-card-container">
            <TextCard
              icon={crossedSwordsIcon}
              title="Konkurencyjna i uczciwa gra"
              content="Podkreślają gry oparte wyłącznie na umiejętnościach, logice, refleksie i zdolnościach kontrolnych, zapewniając uczciwą konkurencję bez losowych elementów."
            />
            <TextCard
              icon={podiumIcon}
              title="Turnieje z nagrodami pieniężnymi"
              content="Platforma oferuje zarówno amatorom, jak i profesjonalistom możliwość udziału w turniejach z atrakcyjnymi nagrodami pieniężnymi."
            />
            <TextCard
              icon={globeIcon}
              title="Globalny zasięg i rozwój zawodowy"
              content="Ich celem jest budowa światowej klasy platformy do gier e-sportowych online, wspierającej rywalizację i rozwój umiejętności graczy na całym świecie."
            />
          </div>
        </div>

        <div className="gamepad-bg">
          <Gallery />
        </div>
        {loading ? (
          <>
            <div id="populargame">
              <PopularGames
                content={pageContent.populargames}
                setBet={setBet}
                setGameType={setGameType}
              />
            </div>
            <CreatingTournament />
            <HowToTournament />
            <DayOfDuel content={pageContent.dayofduel} />
          </>
        ) : (
          ""
        )}
      </div>
    </Layout>
  );
}

export default Home;
